import React from "react";

const AnimatedCirclesSVG = () => {
  return (
    <div className="background-container">
      {/* <?xml version="1.0" encoding="utf-8"?> */}
      <svg
        style={{
          margin: "auto",
          display: "block",
          zIndex: 0,
          position: "relative",
          shapeRendering: "auto",
        }}
        width={1440}
        height={675}
        viewBox="0 0 1440 675"
        xmlns="http://www.w3.org/2000/svg"
        className="background-svg"
        aria-label="Bubbles background"
      >
        <g transform="translate(720,337.5) scale(1,1) translate(-720,-337.5)">
          <defs>
            <filter id="blur-4tbfyzrvos-1" x="-1" y="-1" width="3" height="3">
              <feGaussianBlur stdDeviation="3"></feGaussianBlur>
            </filter>
            <filter id="blur-4tbfyzrvos-2" x="-1" y="-1" width="3" height="3">
              <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
            </filter>
            <filter id="blur-4tbfyzrvos-3" x="-1" y="-1" width="3" height="3">
              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
            </filter>
          </defs>
          <circle
            cx="984.1370317841812"
            cy="0"
            transform="translate(0 372.48497565194106)"
            r="6.6390104802171015"
            fill="#5e6fa3"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-16.011317628108056s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 681.1641750600879;0 -6.164175060087975"
            ></animateTransform>
          </circle>
          <circle
            cx="1218.194721158955"
            cy="0"
            transform="translate(0 574.5655985803506)"
            r="4.047476750143811"
            fill="#93dbe9"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-7.982452591965372s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 679.0474767501438;0 -4.047476750143811"
            ></animateTransform>
          </circle>
          <circle
            cx="1325.924873611717"
            cy="0"
            transform="translate(0 371.3696732303997)"
            r="10.646894859176896"
            fill="#689cc5"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.4690301607295204s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 685.6468948591769;0 -10.646894859176896"
            ></animateTransform>
          </circle>
          <circle
            cx="197.63560894722147"
            cy="0"
            transform="translate(0 307.437670611429)"
            r="7.96581068234415"
            fill="#5e6fa3"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-15.119216884382066s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 682.9658106823441;0 -7.96581068234415"
            ></animateTransform>
          </circle>
          <circle
            cx="1297.4493503877661"
            cy="0"
            transform="translate(0 96.00828051318216)"
            r="5.634634831405056"
            fill="#93dbe9"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-8.514512171293704s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 680.634634831405;0 -5.634634831405056"
            ></animateTransform>
          </circle>
          <circle
            cx="811.7792031811039"
            cy="0"
            transform="translate(0 417.48753276765035)"
            r="8.651726150016533"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-2.0110218955108383s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 683.6517261500165;0 -8.651726150016533"
            ></animateTransform>
          </circle>
          <circle
            cx="1249.5404321232463"
            cy="0"
            transform="translate(0 113.40446417035928)"
            r="9.00070520069466"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.4636812620252555s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 684.0007052006947;0 -9.00070520069466"
            ></animateTransform>
          </circle>
          <circle
            cx="713.8407422757547"
            cy="0"
            transform="translate(0 513.4731197816506)"
            r="5.731721816930209"
            fill="#689cc5"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-6.700656436083454s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 680.7317218169302;0 -5.731721816930209"
            ></animateTransform>
          </circle>
          <circle
            cx="934.9271230670221"
            cy="0"
            transform="translate(0 442.98765525962165)"
            r="7.8523170494032275"
            fill="#93dbe9"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-9.228641313584651s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 682.8523170494033;0 -7.8523170494032275"
            ></animateTransform>
          </circle>
          <circle
            cx="222.05440015392796"
            cy="0"
            transform="translate(0 444.5286902928686)"
            r="11.492838173341616"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-2.1824260018216224s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 686.4928381733416;0 -11.492838173341616"
            ></animateTransform>
          </circle>
          <circle
            cx="1391.689942124994"
            cy="0"
            transform="translate(0 522.1133067853042)"
            r="8.226360085912447"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-1.619513481257444s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 683.2263600859125;0 -8.226360085912447"
            ></animateTransform>
          </circle>
          <circle
            cx="719.0033386458713"
            cy="0"
            transform="translate(0 373.5956160779718)"
            r="11.337517172084773"
            fill="#689cc5"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.6494790634948404s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 686.3375171720847;0 -11.337517172084773"
            ></animateTransform>
          </circle>
          <circle
            cx="1278.8987250263162"
            cy="0"
            transform="translate(0 111.48331746358664)"
            r="6.543645384061097"
            fill="#5e6fa3"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-12.003954610537694s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 681.543645384061;0 -6.543645384061097"
            ></animateTransform>
          </circle>
          <circle
            cx="909.7406306761601"
            cy="0"
            transform="translate(0 78.89848448288325)"
            r="10.282851489257745"
            fill="#93dbe9"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-4.5414284105657625s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 685.2828514892577;0 -10.282851489257745"
            ></animateTransform>
          </circle>
          <circle
            cx="969.1901591169392"
            cy="0"
            transform="translate(0 214.24896736350536)"
            r="5.810315067034493"
            fill="#93dbe9"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.9201337417095594s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 680.8103150670345;0 -5.810315067034493"
            ></animateTransform>
          </circle>
          <circle
            cx="390.68911438679174"
            cy="0"
            transform="translate(0 238.3402080537465)"
            r="10.380196887364246"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.747707959660147s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 682.1014421690106;0 -7.1014421690106175"
            ></animateTransform>
          </circle>
          <circle
            cx="1186.342540801634"
            cy="0"
            transform="translate(0 210.6810825587535)"
            r="10.691720459963884"
            fill="#93dbe9"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-3.2421331953222365s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 685.6917204599639;0 -10.691720459963884"
            ></animateTransform>
          </circle>
          <circle
            cx="775.0360978552985"
            cy="0"
            transform="translate(0 537.4723667541108)"
            r="11.719190233859683"
            fill="#93dbe9"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-8.853602817671295s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 686.7191902338596;0 -11.719190233859683"
            ></animateTransform>
          </circle>
          <circle
            cx="1124.5571485422884"
            cy="0"
            transform="translate(0 636.7747069189483)"
            r="6.136071416443315"
            fill="#689cc5"
            opacity="0.36000000000000004"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-18.51535909419261s"
              dur="20s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 681.1360714164433;0 -6.136071416443315"
            ></animateTransform>
          </circle>
          <circle
            cx="362.2966973859455"
            cy="0"
            transform="translate(0 334.3475238068809)"
            r="15.95681334745262"
            fill="#93dbe9"
            opacity="0.7200000000000001"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-0.04160903244144383s"
              dur="6.666666666666666s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 690.9568133474526;0 -15.95681334745262"
            ></animateTransform>
          </circle>
          <circle
            cx="1386.5306704634602"
            cy="0"
            transform="translate(0 149.03838080091225)"
            r="13.314850478314352"
            fill="#689cc5"
            opacity="0.7200000000000001"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-1.0716975238546858s"
              dur="6.666666666666666s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 688.3148504783144;0 -13.314850478314352"
            ></animateTransform>
          </circle>
          <circle
            cx="990.5474490414086"
            cy="0"
            transform="translate(0 198.5138178010281)"
            r="10.804656187048037"
            fill="#93dbe9"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-6.35780963185935s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 685.8046561870481;0 -10.804656187048037"
            ></animateTransform>
          </circle>
          <circle
            cx="1396.7881111040024"
            cy="0"
            transform="translate(0 320.7480050953805)"
            r="8.63825974384323"
            fill="#5e6fa3"
            opacity="0.63"
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              begin="-6.500128418905032s"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 683.6382597438433;0 -8.63825974384323"
            ></animateTransform>
          </circle>
        </g>
      </svg>
    </div>
  );
};

export default AnimatedCirclesSVG;
